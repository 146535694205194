import { Injectable } from '@angular/core';
import DataGrid from 'devextreme/ui/data_grid';
import DataSource from 'devextreme/data/data_source';

import { Documents, Sale, SalesLines, SaleFilterValidation, Purchase, PurchaseLines, PurchaseFilterValidation, Artigos, ArtigoUnidades, Unidades, Approval, RoleApprovalWorkflow, ApprovalWorkflow, Attachament, } from '../models';
import { Utilities } from './utilities';
import { UtilitiesTreeView } from './utilities-treeview';

@Injectable()
export class UtilitiesGrid {
  //#region Isntance
  public static instance(elementId: string) {
    try {
      const element = document.getElementById(elementId);
      const instance = DataGrid.getInstance(element) as DataGrid;
      return instance;
    } catch (e) {
      console.log('UtilitiesGrid::instance::' + e);
    }
  }

  public static instanceRefresh(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.refresh();
    } catch (e) {
      console.log('UtilitiesGrid::instanceRefresh::' + e);
    }
  }

  public static instanceSave(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.saveEditData();
    } catch (e) {
      console.log('UtilitiesGrid::instanceSave::' + e);
    }
  }
  public static instanceFocus(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.endUpdate();
      instance.focus();
      instance.saveEditData();
      instance.closeEditCell();
    } catch (e) {
      console.log('UtilitiesGrid::instanceFocus::' + e);
    }
  }

  public static instanceSelectRow(elementId: string, keys: any[]) {
    try {
      const instance = this.instance(elementId);
      instance.selectRows(keys, false);
    } catch (e) {
      console.log('UtilitiesGrid::instanceSelectRow::' + e);
    }
  }

  public static instanceBeginUpdate(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.beginUpdate();
    } catch (e) {
      console.log('UtilitiesGrid::instanceBeginUpdate::' + e);
    }
  }

  public static instanceEndUpdate(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.endUpdate();
    } catch (e) {
      console.log('UtilitiesGrid::instanceEndUpdate::' + e);
    }
  }

  public static instanceCollapseRow(elementId: string, key: any) {
    try {
      const instance = this.instance(elementId);
      instance.collapseRow(key);
    } catch (e) {
      console.log('UtilitiesGrid::collapseRow::' + e);
    }
  }

  public static instanceCollapseAll(elementId: string, groupIndex?: number) {
    try {
      const instance = this.instance(elementId);
      instance.collapseAll(groupIndex);
    } catch (e) {
      console.log('UtilitiesGrid::collapseAll::' + e);
    }
  }

  public static instanceExpandRow(elementId: string, key: any) {
    try {
      const instance = this.instance(elementId);
      instance.expandRow(key);
    } catch (e) {
      console.log('UtilitiesGrid::expandRow::' + e);
    }
  }

  public static instanceExpandAll(elementId: string, groupIndex?: number) {
    try {
      const instance = this.instance(elementId);
      instance.expandAll(groupIndex);
    } catch (e) {
      console.log('UtilitiesGrid::expandAll::' + e);
    }
  }

  public static updateCell(elementId: string, rowIndex: number, dataField: string, value: any) {
    try {
      const element = document.getElementById(elementId);
      const instance = DataGrid.getInstance(element) as DataGrid;
      const r = instance.getSelectedRowKeys();
      const rs = instance.getSelectedRowsData();
      instance.cellValue(rowIndex, dataField, value);
      instance.saveEditData();
    } catch (e) {
      console.log('UtilitiesGrid::updateCell::' + e);
    }
  }
  //#endregion

  //#region Events

  //#region onRowInserting
  public static onRowInserting(e) {
    if (e.dataField && e.dataField === 'precUnit' && e.parentType === 'dataRow') {
      if (e.row.data) {
        e.allowEditing = e.row.data.allowPriceUnit;
        e.editorOptions.disabled = !e.row.data.allowPriceUnit;
      } else {
        e.allowEditing = false;
        e.editorOptions.disabled = true;
      }
    }
  }
  //#endregion

  //#region onEditorPreparing
  public static onEditorPreparing(e) {
    if (e.dataField && e.dataField === 'precUnit' && e.parentType === 'dataRow') {
      if (e.row.data) {
        if (e.row.data.precUnit !== e.row.data.precUnitOrig) {

        }
      }
    //  if (e.row.data) {
    //    e.allowEditing = e.row.data.allowPriceUnit;
    //    e.editorOptions.disabled = !e.row.data.allowPriceUnit;
    //  }
    //  else {
    //    e.allowEditing = false;
    //    e.editorOptions.disabled = true;
    //  }
     }
  }
  //#endregion

  //#region onRowUpdated
  public static onRowUpdated(e) {
  }
  //#endregion

  //#region Sales
  //#region onRowValidating
  public static onRowValidating(e, saleEdit: Sale, dataLineSale: SalesLines[]) {
    if (e.isValid) {
      try {
        let unidades: ArtigoUnidades[];
        if (e.oldData.unidades && (e.oldData.unidades as ArtigoUnidades[]) && (e.oldData.unidades as ArtigoUnidades[]).length > 0) {
          unidades = (e.oldData.unidades as ArtigoUnidades[]);
        } else {
          unidades = [];
        }
        if (e.newData && e.newData.unidade) {
          if (unidades.length > 0) {
            const unidade = unidades.find(f => f.unidade === e.newData.unidade);
            if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * unidade.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            } else {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            }

            if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
              e.oldData.taxaIva = 1;
            }
            e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * e.oldData.taxaIva), 0, 0, 0, 0);

            e.oldData.pricePerUnit = ((e.oldData.totalPrice / unidade.conversao) / e.oldData.quantidade);
          } else {
            e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);

            if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
              e.oldData.taxaIva = 1;
            }
            e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * e.oldData.taxaIva), 0, 0, 0, 0);
          }
        } else if (e.newData && e.newData.desconto3 !== undefined) {
          if (unidades.length > 0) {
            const unidade = unidades.find(f => f.unidade === e.oldData.unidade);
            if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * unidade.precUnit), e.oldData.desconto, e.oldData.desconto2, e.newData.desconto3, e.oldData.descontoFinanceiro);
            } else {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.newData.desconto3, e.oldData.descontoFinanceiro);
            }
          } else {
            e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.newData.desconto3, e.oldData.descontoFinanceiro);
          }

          if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
            e.oldData.taxaIva = 1;
          }
          e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * e.oldData.taxaIva), 0, 0, 0, 0);

          if (unidades.length > 0) {
            const pricePerUnit = (e.oldData.unidades as ArtigoUnidades[]).find(f => f.unidade === e.oldData.unidade);
            if (pricePerUnit !== undefined) {
              e.oldData.pricePerUnit = ((e.oldData.totalPrice / pricePerUnit.conversao) / e.oldData.quantidade);
            }
          } else {
            e.oldData.pricePerUnit = ((e.oldData.totalPrice / 1) / e.oldData.quantidade);
          }
        } else if (e.newData && e.newData.quantidade) {
          if (unidades.length > 0) {
            const unidade = unidades.find(f => f.unidade === e.oldData.unidade);
            if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.newData.quantidade * unidade.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            } else {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.newData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            }
          } else {
            e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.newData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
          }

          if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
            e.oldData.taxaIva = 1;
          }
          e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * e.oldData.taxaIva), 0, 0, 0, 0);

          if (unidades.length > 0) {
            const pricePerUnit = (e.oldData.unidades as ArtigoUnidades[]).find(f => f.unidade === e.oldData.unidade);
            if (pricePerUnit !== undefined) {
              e.oldData.pricePerUnit = ((e.oldData.totalPrice / pricePerUnit.conversao) / e.newData.quantidade);
            }
          } else {
            e.oldData.pricePerUnit = ((e.oldData.totalPrice / 1) / e.oldData.quantidade);
          }
        }

        if (Number.isNaN(e.oldData.pricePerUnit)) {
          e.oldData.pricePerUnit = 0;
        }

        if (Number.isNaN(e.oldData.boxPrice)) {
          e.oldData.boxPrice = 0;
        }

        e.oldData.totalPrice = Utilities.roundNumber((e.oldData.totalPrice as number), 2);
        if (e.oldData.totalPriceTax === undefined || (e.oldData.totalPriceTax !== undefined && e.oldData.totalPriceTax === null)) {
          e.oldData.totalPriceTax = 0;
        }

        if (saleEdit && saleEdit !== undefined) {
          let total = 0;
          for (const l of dataLineSale) {
            if (l.totalPriceTax === undefined || (l.totalPriceTax !== undefined && l.totalPriceTax === null)) {
              total += 0;
            } else {
              total += l.totalPriceTax;
            }
          }
          saleEdit.totalDocumento = Utilities.roundNumber(total, 2);
        }
      } catch { }
      // e.component.endUpdate();
    }
  }
  //#endregion

  //#region onRowValidatingTopSales
  public static onRowValidatingTopSales(e, salesId: any, saleEdit: Sale, dataLineSale: SalesLines[], dataArtigos: Artigos[], unidadesErp: Unidades[], defaultUnit: string) {
    if (e.isValid) {
      try {
        if (e.key.id !== salesId) {
          if (e.newData.isSelect) {
            for (let l of e.key.lines) {
              try {
                const unidades = dataArtigos.find(f => f.artigo === l.artigo);
                const unidadeErp = unidadesErp.find(f => f.unidade.startsWith('UN'));
                l = this.artigoUnidadesPrice(l, unidades, saleEdit.descontoFinanceiro, unidadeErp, defaultUnit, true);
              } catch { }
              dataLineSale.push(l);
            }
          } else {
            for (const l of e.key.lines) {
              const index = dataLineSale.findIndex(f => f.id === l.id);
              dataLineSale.splice(index, 1);
            }
          }
          let total = 0;
          for (const l of dataLineSale) {
            total += l.totalPriceTax;
          }
          saleEdit.totalDocumento = Utilities.roundNumber(total, 2);
        }
      } catch { }
      // e.component.endUpdate();
    }
  }
  //#endregion

  //#region onRowRemoved
  public static onRowRemoved(e, saleEdit: Sale, dataLineSale: SalesLines[]) {
    let total = 0;
    for (const l of dataLineSale) {
      if (l.totalPriceTax === undefined || (l.totalPriceTax !== undefined && l.totalPriceTax === null)) {
        total += 0;
      } else {
        total += l.totalPriceTax;
      }
    }
    saleEdit.totalDocumento = Utilities.roundNumber(total, 2);
  }
  //#endregion
  //#endregion

  //#region Purchases
  //#region onRowValidating
  public static onRowPurchaseValidating(e, purchaseEdit: Purchase, dataLinePurchase: PurchaseLines[], artigos: Artigos[]) {
    if (e.isValid) {
      try {
        let unidades: ArtigoUnidades[];
        if (e.oldData.unidades && (e.oldData.unidades as ArtigoUnidades[]) && (e.oldData.unidades as ArtigoUnidades[]).length > 0) {
          unidades = (e.oldData.unidades as ArtigoUnidades[]);
        } else {
          unidades = [];
        }
        let taxaIva = 1;
        if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
          taxaIva = 1;
        } else if (!Utilities.isNullOrUndefined(e.oldData.taxaIva) && e.oldData.taxaIva === 0) {
          taxaIva = 1;
        } else {
          taxaIva = e.oldData?.taxaIva;
        }

        if (isNaN(taxaIva)) {
          taxaIva = 1;
        }
        if (e.newData && e.newData.artigo) {
          if (!Utilities.isNullOrUndefined(e.newData.artigo)) {
            const _art = artigos.find(f => f.artigo === e.newData.artigo);
            if (!Utilities.isNullOrUndefined(_art) && !Utilities.isNullOrUndefined(_art.taxaIva)) {
              if (_art.taxaIva > 0) {
                taxaIva = _art.taxaIva;
                e.oldData.taxaIva = _art.taxaIva;
              }
            }

            if (!Utilities.isNullOrUndefined(e.oldData.artigo) && e.oldData.artigo !== e.newData.artigo && unidades.length > 0) {
              const unidade = unidades.find(f => f.unidade === e.oldData.unidade && f.qtMax >= e.oldData.quantidade && f.qtMin <= e.oldData.quantidade);
              if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
                if (e.oldData.precUnit === e.oldData.precUnitOrig) {
                  e.oldData.precUnit = unidade.precUnit;
                  e.oldData.precUnitOrig = unidade.precUnit;
                } else if (e.oldData.precUnit === 0) {
                  e.oldData.precUnit = unidade.precUnit;
                  e.oldData.precUnitOrig = unidade.precUnit;
                } else if (e.oldData.precUnitOrig === 0) {
                  e.oldData.precUnitOrig = unidade.precUnit;
                }

                e.oldData.precUnit = unidade.precUnit;
                e.oldData.precUnitOrig = unidade.precUnit;
              } else {

                e.oldData.precUnit = 0;
                e.oldData.precUnitOrig = 0;

              }

            } else {
              e.oldData.precUnit = 0;
              e.oldData.precUnitOrig = 0;
            }
            e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);

            if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
              e.oldData.taxaIva = 0;
            }
            e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * taxaIva), 0, 0, 0, 0);

            if (unidades.length > 0) {
              const pricePerUnit = (e.oldData.unidades as ArtigoUnidades[]).find(f => f.unidade === e.oldData.unidade);
              if (pricePerUnit !== undefined) {
                e.oldData.pricePerUnit = ((e.oldData.totalPrice / pricePerUnit.conversao) / e.oldData.quantidade);
              }
            } else {
              e.oldData.pricePerUnit = ((e.oldData.totalPrice / 1) / e.oldData.quantidade);
            }


          }

        } else if (e.newData && e.newData.unidade) {
          if (unidades.length > 0) {
            const unidade = unidades.find(f => f.unidade === e.newData.unidade);
            if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * unidade.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            } else {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            }

            if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
              e.oldData.taxaIva = 0;
            }
            e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * taxaIva), 0, 0, 0, 0);

            e.oldData.pricePerUnit = ((e.oldData.totalPrice / unidade.conversao) / e.oldData.quantidade);
          } else {
            e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);

            if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
              e.oldData.taxaIva = 0;
            }
            e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * taxaIva), 0, 0, 0, 0);
          }
        } else if (e.newData && e.newData.desconto3 !== undefined) {
          if (unidades.length > 0) {
            const unidade = unidades.find(f => f.unidade === e.oldData.unidade);
            if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * unidade.precUnit), e.oldData.desconto, e.oldData.desconto2, e.newData.desconto3, e.oldData.descontoFinanceiro);
            } else {
              e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.newData.desconto3, e.oldData.descontoFinanceiro);
            }
          } else {
            e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.newData.desconto3, e.oldData.descontoFinanceiro);
          }

          if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
            e.oldData.taxaIva = 0;
          }
          e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * taxaIva), 0, 0, 0, 0);

          if (unidades.length > 0) {
            const pricePerUnit = (e.oldData.unidades as ArtigoUnidades[]).find(f => f.unidade === e.oldData.unidade);
            if (pricePerUnit !== undefined) {
              e.oldData.pricePerUnit = ((e.oldData.totalPrice / pricePerUnit.conversao) / e.oldData.quantidade);
            }
          } else {
            e.oldData.pricePerUnit = ((e.oldData.totalPrice / 1) / e.oldData.quantidade);
          }
        } else if (e.newData && e.newData.quantidade) {
          if (unidades.length > 0) {
            const unidade = unidades.find(f => f.unidade === e.oldData.unidade && f.qtMax >= e.newData.quantidade && f.qtMin <= e.newData.quantidade);
            if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
              if (e.oldData.precUnit === e.oldData.precUnitOrig) {
                e.oldData.precUnit = unidade.precUnit;
                e.oldData.precUnitOrig = unidade.precUnit;
              } else if (e.oldData.precUnit === 0) {
                e.oldData.precUnit = unidade.precUnit;
                e.oldData.precUnitOrig = unidade.precUnit;
              } else if (e.oldData.precUnitOrig === 0) {
                e.oldData.precUnitOrig = unidade.precUnit;
              }

              e.oldData.precUnit = unidade.precUnit;
              e.oldData.precUnitOrig = unidade.precUnit;
              // e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.newData.quantidade * unidade.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            } else {
              // e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.newData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
            }
          } else {
           // e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.newData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);
          }

          e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.newData.quantidade * e.oldData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);

          if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
            e.oldData.taxaIva = 0;
          }
          e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * taxaIva), 0, 0, 0, 0);

          if (unidades.length > 0) {
            const pricePerUnit = (e.oldData.unidades as ArtigoUnidades[]).find(f => f.unidade === e.oldData.unidade);
            if (pricePerUnit !== undefined) {
              e.oldData.pricePerUnit = ((e.oldData.totalPrice / pricePerUnit.conversao) / e.newData.quantidade);
            }
          } else {
            e.oldData.pricePerUnit = ((e.oldData.totalPrice / 1) / e.oldData.quantidade);
          }
        } else if (e.newData && e.newData.precUnit) {
          if (e.newData.precUnit !== e.newData.precUnitOrig) {

          }

          e.oldData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (e.oldData.quantidade * e.newData.precUnit), e.oldData.desconto, e.oldData.desconto2, e.oldData.desconto3, e.oldData.descontoFinanceiro);

          if (e.oldData.taxaIva === undefined || (e.oldData.taxaIva !== undefined && e.oldData.taxaIva === null)) {
            e.oldData.taxaIva = 0;
          }
          e.oldData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (e.oldData.totalPrice * taxaIva), 0, 0, 0, 0);

          if (unidades.length > 0) {
            const pricePerUnit = (e.oldData.unidades as ArtigoUnidades[]).find(f => f.unidade === e.oldData.unidade);
            if (pricePerUnit !== undefined) {
              e.oldData.pricePerUnit = ((e.oldData.totalPrice / pricePerUnit.conversao) / e.oldData.quantidade);
            }
          } else {
            e.oldData.pricePerUnit = ((e.oldData.totalPrice / 1) / e.oldData.quantidade);
          }
        }

        if (Number.isNaN(e.oldData.pricePerUnit)) {
          e.oldData.pricePerUnit = 0;
        }

        if (Number.isNaN(e.oldData.boxPrice)) {
          e.oldData.boxPrice = 0;
        }

        e.oldData.totalPrice = Utilities.roundNumber((e.oldData.totalPrice as number), 2);
        if (e.oldData.totalPriceTax === undefined || (e.oldData.totalPriceTax !== undefined && e.oldData.totalPriceTax === null)) {
          e.oldData.totalPriceTax = 0;
        }

        if (purchaseEdit && purchaseEdit !== undefined) {
          let total = 0;
          for (const l of dataLinePurchase) {
            if (l.totalPriceTax === undefined || (l.totalPriceTax !== undefined && l.totalPriceTax === null)) {
              total += 0;
            } else {
              total += l.totalPriceTax;
            }
          }
          purchaseEdit.totalDocumento = Utilities.roundNumber(total, 2);
        }
      } catch { }
      //if (e.newData) {
      //  Object.assign(e.key, e.newData);
      //}
      let aa = e.component._controllers.validating._getValidationData(e.key);
      if (aa) {

      }
      // e.component.endUpdate();
    }
  }
  //#endregion

  //#region onRowValidatingTopPurchase
  public static onRowValidatingTopPurchase(e, purchaseId: any, purchaseEdit: Purchase, dataLinePurchase: PurchaseLines[], dataArtigos: Artigos[], unidadesErp: Unidades[], defaultUnit: string) {
    if (e.isValid) {
      try {
        if (e.key.id !== purchaseId) {
          if (e.newData.isSelect) {
            for (let l of e.key.lines) {
              try {
                const unidades = dataArtigos.find(f => f.artigo === l.artigo);
                const unidadeErp = unidadesErp.find(f => f.unidade.startsWith('UN'));
                l = this.artigoUnidadesPrice(l, unidades, purchaseEdit.descontoFinanceiro, unidadeErp, defaultUnit, true);
              } catch { }
              dataLinePurchase.push(l);
            }
          } else {
            for (const l of e.key.lines) {
              const index = dataLinePurchase.findIndex(f => f.id === l.id);
              dataLinePurchase.splice(index, 1);
            }
          }
          let total = 0;
          for (const l of dataLinePurchase) {
            total += l.totalPriceTax;
          }
          purchaseEdit.totalDocumento = Utilities.roundNumber(total, 2);
        }
      } catch { }
      // e.component.endUpdate();
    }
  }
  //#endregion

  //#region onRowRemoved
  public static onRowRemovedPurchase(e, purchaseEdit: Purchase, dataLineSale: PurchaseLines[]) {
    let total = 0;
    for (const l of dataLineSale) {
      if (l.totalPriceTax === undefined || (l.totalPriceTax !== undefined && l.totalPriceTax === null)) {
        total += 0;
      } else {
        total += l.totalPriceTax;
      }
    }
    purchaseEdit.totalDocumento = Utilities.roundNumber(total, 2);
  }
  //#endregion
  //#endregion

  //#region Attachament
  //#region onRowValidating
  public static onRowAttachamentValidating(e, dataLineAttachament: Attachament[]): boolean {
    let flag = false;
    if (e.isValid) {
      try {
        if (e.newData && e.newData.tiposDocAttach) {
          flag = true;
          // e.oldData.name = `${e.oldData.key}-${e.newData.tiposDocAttach}`;
        }
      } catch { }
    }
    return flag;
  }
  //#endregion

  //#region setTiposDocAttachValue
  public static setTiposDocAttachValue(newData, value, currentRowData, dataLineAttachament: Attachament[]) {
    newData.tiposDocAttach = value;
    if (currentRowData.key && value) {
      const _r = dataLineAttachament?.filter(f => f.tiposDocAttach === value);
      if (_r && _r.length > 0) {
        newData.name = `${currentRowData.key}-${value}(${(_r.length + 1)})`;
      } else {
        newData.name = `${currentRowData.key}-${value}`;
      }
      if (Utilities.isNullOrUndefined(currentRowData.indexRows)) {
        let max = Math.max(...dataLineAttachament.filter(f => f.indexRows > 0).map(m => m.indexRows));
        if (!max) {
          max = 0;
        }
        dataLineAttachament.filter(f => f.key === currentRowData.key && f.numLine === currentRowData.numLine).map((m) => {
          m.name = newData.name;
          m.indexRows = max + 1;
          m.tiposDocAttach = value;
        });
        currentRowData.indexRows = max + 1;
      }
      currentRowData.tiposDocAttach = value;
    }
  }
  //#endregion
  //#endregion

  //#region setArtigoUnidadesValue
  public static setArtigoUnidadesValue(newData, value, currentRowData, defaultUnit: string) {
    newData.unidade = value;
    if (currentRowData.unidades && (currentRowData.unidades as ArtigoUnidades[]) && (currentRowData.unidades as ArtigoUnidades[]).length > 0) {
      const unidade = (currentRowData.unidades as ArtigoUnidades[]).find(f => f.unidade === currentRowData.unidade);
      if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
        currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * unidade.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
      } else {
        currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * currentRowData.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
      }

      currentRowData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (currentRowData.totalPrice * currentRowData.taxaIva), 0, 0, 0, 0);

      const pricePerUnit = (currentRowData.unidades as ArtigoUnidades[]).find(f => f.unidade.startsWith(defaultUnit));
      if (pricePerUnit !== undefined) {
        currentRowData.pricePerUnit = (currentRowData.totalPrice / pricePerUnit.conversao) / currentRowData.quantidade;
      } else {
        currentRowData.pricePerUnit = (currentRowData.totalPrice / 1) / currentRowData.quantidade;
      }

      if (Number.isNaN(currentRowData.pricePerUnit)) {
        currentRowData.pricePerUnit = 0;
      }

      if (Number.isNaN(currentRowData.boxPrice)) {
        currentRowData.boxPrice = 0;
      }
    } else {
      currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * currentRowData.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
      currentRowData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (currentRowData.totalPrice * currentRowData.taxaIva), 0, 0, 0, 0);
    }
    // currentRowData.totalPrice = Utilities.roundNumber( ( ( currentRowData.quantidade * currentRowData.precUnit ) - ( ( currentRowData.quantidade * currentRowData.precUnit ) * ( ( currentRowData.desconto + currentRowData.desconto2 + currentRowData.desconto3 + currentRowData.descontoFinanceiro ) / 100 ) ) ), 2 );
    currentRowData.unidade = value;
  }
  //#endregion

  //#region setArtigo
  public static setArtigoValue(newData, value, currentRowData, defaultUnit: string) {
    newData.artigo = value;
    if (currentRowData.unidades && (currentRowData.unidades as ArtigoUnidades[]) && (currentRowData.unidades as ArtigoUnidades[]).length > 0) {
      const unidade = (currentRowData.unidades as ArtigoUnidades[]).find(f => f.unidade === currentRowData.unidade);
      if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
        // currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * unidade.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
      } else {
        // currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * currentRowData.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
      }

      //  currentRowData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (currentRowData.totalPrice * currentRowData.taxaIva), 0, 0, 0, 0);

      //  const pricePerUnit = (currentRowData.unidades as ArtigoUnidades[]).find(f => f.unidade.startsWith(defaultUnit));
      //  if (pricePerUnit !== undefined) {
      //    currentRowData.pricePerUnit = (currentRowData.totalPrice / pricePerUnit.conversao) / currentRowData.quantidade;
      //  }
      //  else {
      //    currentRowData.pricePerUnit = (currentRowData.totalPrice / 1) / currentRowData.quantidade;
      //  }

      //  if (Number.isNaN(currentRowData.pricePerUnit)) {
      //    currentRowData.pricePerUnit = 0;
      //  }

      //  if (Number.isNaN(currentRowData.boxPrice)) {
      //    currentRowData.boxPrice = 0;
      //  }
      // }
      // else {
      //  currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * currentRowData.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
      //  currentRowData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (currentRowData.totalPrice * currentRowData.taxaIva), 0, 0, 0, 0);
      // }
      // currentRowData.totalPrice = Utilities.roundNumber( ( ( currentRowData.quantidade * currentRowData.precUnit ) - ( ( currentRowData.quantidade * currentRowData.precUnit ) * ( ( currentRowData.desconto + currentRowData.desconto2 + currentRowData.desconto3 + currentRowData.descontoFinanceiro ) / 100 ) ) ), 2 );
      currentRowData.artigo = value;
    }
  }
  //#endregion

  //#region setClassificacao
  public static setClassificacaoValue(newData, value, currentRowData, defaultUnit: string) {
    newData.classificacao = value;
    // if (currentRowData.unidades && (currentRowData.unidades as ArtigoUnidades[]) && (currentRowData.unidades as ArtigoUnidades[]).length > 0) {
    //  const unidade = (currentRowData.unidades as ArtigoUnidades[]).find(f => f.unidade === currentRowData.unidade);
    //  if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
    //    currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * unidade.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
    //  }
    //  else {
    //    currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * currentRowData.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
    //  }

    //  currentRowData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (currentRowData.totalPrice * currentRowData.taxaIva), 0, 0, 0, 0);

    //  const pricePerUnit = (currentRowData.unidades as ArtigoUnidades[]).find(f => f.unidade.startsWith(defaultUnit));
    //  if (pricePerUnit !== undefined) {
    //    currentRowData.pricePerUnit = (currentRowData.totalPrice / pricePerUnit.conversao) / currentRowData.quantidade;
    //  }
    //  else {
    //    currentRowData.pricePerUnit = (currentRowData.totalPrice / 1) / currentRowData.quantidade;
    //  }

    //  if (Number.isNaN(currentRowData.pricePerUnit)) {
    //    currentRowData.pricePerUnit = 0;
    //  }

    //  if (Number.isNaN(currentRowData.boxPrice)) {
    //    currentRowData.boxPrice = 0;
    //  }
    // }
    // else {
    //  currentRowData.totalPrice = Utilities.CalculateDiscountRound(true, 2, (currentRowData.quantidade * currentRowData.precUnit), currentRowData.desconto, currentRowData.desconto2, currentRowData.desconto3, currentRowData.descontoFinanceiro);
    //  currentRowData.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (currentRowData.totalPrice * currentRowData.taxaIva), 0, 0, 0, 0);
    // }
    // currentRowData.totalPrice = Utilities.roundNumber( ( ( currentRowData.quantidade * currentRowData.precUnit ) - ( ( currentRowData.quantidade * currentRowData.precUnit ) * ( ( currentRowData.desconto + currentRowData.desconto2 + currentRowData.desconto3 + currentRowData.descontoFinanceiro ) / 100 ) ) ), 2 );
    currentRowData.classificacao = value;
  }
  //#endregion

  //#endregion


  public static isGridValid(elementId: string): boolean {
    let flag = false;

    const gridInstance = UtilitiesGrid.instance(elementId) as any;

    const source = UtilitiesGrid.instance(elementId).getDataSource();
    if (source) {

    }

    const rows = UtilitiesGrid.instance(elementId).getVisibleRows();
    if (rows) {
      for (const r of rows) {
        if (r.data) {

        }
      }
    }

    const value = gridInstance.cellValue(0, 0);

    // gridInstance.cellValue(0, 0, value);
    console.log(gridInstance.getController('validating').validate(true));
    flag = false;
    return flag;
  }

  //#region Filter
  public static Filters(itemData: any, dataCatalogCategory: any[], isGeneric: boolean): any[] {
    const filter: any[] = [];
    try {
      const filterProduct = UtilitiesTreeView.getFilterProducts(itemData);
      if (filterProduct && filterProduct !== undefined && filterProduct.length > 0) {
        for (const r of filterProduct) {
          let value: string;
          if (r.groupName) {
            if (isGeneric) {
              filter.push([r.groupName, '=', r.name]);
            }
            value = r.groupName;
          } else {
            const groupName = dataCatalogCategory.find(f => f.id === r.idCatalogsCategories);
            if (groupName) {
              if (groupName.name === 'Marca') {
                value = 'marca';
              } else if (groupName.name === 'Categoria') {
                value = 'categoria';
              } else if (groupName.name === 'SubCategoria') {
                value = 'subCategoria';
              } else if (groupName.name === 'Variant') {
                value = 'variants';
              } else if (groupName.name === 'Artigo') {
                value = 'sku';
              }
              if (value && value !== undefined && value === 'sku') {
                filter.push([value, '=', r.name]);
              }
            }
          }
          if (value && value !== undefined && value === 'sku') {
            filter.push('or');
          }
        }
        if (filter.length > 0) {
          filter.splice(filter.length - 1, 1);
        }
      } else {
        filter.push([itemData.groupName, '=', itemData.name]);
      }
    } catch (e) {
      console.log('Filters::' + e);
    }
    return filter;
  }

  public static FiltersOld(itemData: any, dataCatalogCategory: any[]): any[] {
    const filter: any[] = [];
    try {
      const filterProduct = UtilitiesTreeView.getFilterProducts(itemData);
      if (filterProduct && filterProduct !== undefined && filterProduct.length > 0) {
        const filterOr: any[] = [];
        const filterAnd: any[] = [];
        let isAnd = false;
        for (const r of filterProduct) {
          let value: string;
          if (r.groupName) {
            filterAnd.push([r.groupName, '=', r.name]);
            value = r.groupName;
          } else {
            const groupName = dataCatalogCategory.find(f => f.id === r.idCatalogsCategories);
            if (groupName) {
              if (groupName.name === 'Marca') {
                value = 'marca';
              } else if (groupName.name === 'Categoria') {
                value = 'categoria';
              } else if (groupName.name === 'SubCategoria') {
                value = 'subCategoria';
              } else if (groupName.name === 'Variant') {
                value = 'variants';
              } else if (groupName.name === 'Artigo') {
                value = 'sku';
              }
              if (value && value !== undefined) {
                if (isAnd) {
                  filterAnd.push([value, '=', r.name]);
                } else {
                  filterOr.push([value, '=', r.name]);
                }
              }
            }
          }
          if (value && value !== undefined && value === 'sku') {
            filterOr.push('or');
            isAnd = false;
          } else {
            filterAnd.push('and');
            isAnd = true;
          }
        }
        if (filterAnd.length > 0 && filterOr.length > 0) {
          if (!(filterAnd[0] instanceof Array)) {
            filterAnd.splice(0, 1);
          }
          if (!(filterOr[0] instanceof Array)) {
            filterOr.splice(0, 1);
          }
          for (const and of filterAnd) {
            filter.push(and);
          }
          filter.push(filterOr.splice(filterOr.length - 1, 1));
        } else if (filterAnd.length > 0) {
          if (!(filterAnd[0] instanceof Array)) {
            filterAnd.splice(0, 1);
          }
          for (const and of filterAnd) {
            filter.push(and);
          }
          filter.splice(filter.length - 1, 1);
        } else if (filterOr.length > 0) {
          if (!(filterOr[0] instanceof Array)) {
            filterOr.splice(0, 1);
          }
          for (const or of filterOr) {
            filter.push(or);
          }
          filter.splice(filter.length - 1, 1);
        }
      } else {
        filter.push([itemData.groupName, '=', itemData.name]);
      }
    } catch (e) {
      console.log('Filters::' + e);
    }
    return filter;
  }
  //#endregion

  //#region SalesLine
  public static addSalesLines(idDoc: string, salesmanLine: string, descontoFinanceiro: number, index: number, lines: Artigos[]): SalesLines[] {
    const result: SalesLines[] = [];
    for (const l of lines) {
      try {
        // if ( this.dataLineSale.filter( f => f.artigo == l.artigo ).length == 0 )
        // {
        const line: SalesLines = new SalesLines();
        line.artigo = l.artigo;
        line.desconto = l.desconto;
        line.desconto2 = l.desconto2;
        line.desconto3 = l.desconto3;
        line.descricao = l.descricao;
        line.precUnit = l.precUnit;
        line.numLine = index;
        line.boxPrice = l.boxPrice;
        line.pricePerUnit = l.pricePerUnit;
        line.unidade = l.unidade;
        line.idDoc = idDoc;
        line.quantidade = l.quantidade;
        line.precoLiquido = l.totalPrice;
        line.totalPrice = l.totalPrice;
        line.totalPriceTax = l.totalPriceTax;
        line.taxaIva = l.taxaIva;
        line.vendedor = salesmanLine;
        line.descontoFinanceiro = descontoFinanceiro;
        line.unidades = l.unidades;
        line.allowPriceUnit = l.allowPriceUnit;
        result.push(line);

        l.quantidade = 0;
        index += 1;
      } catch (e) {
        console.log('addLines:lines:' + e);
      }
    }
    return result;
  }

  //#endregion

  //#region PurchasesLine
  public static addPurchasesLines(idDoc: string, salesmanLine: string, descontoFinanceiro: number, index: number, lines: Artigos[]): PurchaseLines[] {
    const result: PurchaseLines[] = [];
    for (const l of lines) {
      try {
        // if ( this.dataLineSale.filter( f => f.artigo == l.artigo ).length == 0 )
        // {
        const line: PurchaseLines = new PurchaseLines();
        line.artigo = l.artigo;
        line.desconto = l.desconto;
        line.desconto2 = l.desconto2;
        line.desconto3 = l.desconto3;
        line.descricao = l.descricao;
        line.precUnit = l.precUnit;
        line.numLine = index;
        line.boxPrice = l.boxPrice;
        line.pricePerUnit = l.pricePerUnit;
        line.unidade = l.unidade;
        line.idDoc = idDoc;
        line.quantidade = l.quantidade;
        line.precoLiquido = l.totalPrice;
        line.totalPrice = l.totalPrice;
        line.totalPriceTax = l.totalPriceTax;
        line.taxaIva = l.taxaIva;
        line.vendedor = salesmanLine;
        line.descontoFinanceiro = descontoFinanceiro;
        line.unidades = l.unidades;
        line.allowPriceUnit = l.allowPriceUnit;
        result.push(line);

        l.quantidade = 0;
        index += 1;
      } catch (e) {
        console.log('addLines:lines:' + e);
      }
    }
    return result;
  }

  //#endregion

  //#region Unidade
  public static getUnidade(documents: Documents[], tipoDoc: string): string {
    let unidade = 'UN';
    if (documents && documents != null && tipoDoc && tipoDoc !== undefined) {
      const dUnit = documents.find(f => f.tipoDoc === tipoDoc);
      if (dUnit !== undefined && dUnit && dUnit.defaultUnit) {
        unidade = dUnit.defaultUnit;
      }
    }
    return unidade;
  }
  //#endregion

  //#region artigos
  //#region artigosConversao
  public static ArtigosConversao(artigos: Artigos[], unidades: Unidades[], defaultUnit: string): Artigos[] {
    const _artigos: Artigos[] = [];
    if (artigos) {
      for (const art of artigos) {
        art.boxPrice = 0;
        art.pricePerUnit = 0;

        if (art.unidades !== undefined && art.unidades != null && art.unidades.length > 0) {
          for (const uni of art.unidades) {
            if (uni.precUnit === undefined || (uni.precUnit !== undefined && uni.precUnit === null)) {
              art.precUnit = 0;
            }

            if (uni.unidade === defaultUnit) {
              uni.conversao = 1;
            } else if (uni.unidade.startsWith(defaultUnit)) {
              uni.conversao = 1;
            } else {
              const conv = unidades.find(f => f.unidade.startsWith(defaultUnit));
              if (conv !== undefined && conv !== null) {
                if (conv.unidadesConversao !== undefined && conv.unidadesConversao !== null && conv.unidadesConversao.length > 0) {
                  const uc = conv.unidadesConversao.find(f => f.unidadeFrom === uni.unidade);
                  if (uc !== undefined && uc !== null) {
                    uni.conversao = uc.unidadeConversao;
                  } else {
                    uni.conversao = 1;
                  }
                } else {
                  uni.conversao = 1;
                }
              } else {
                uni.conversao = 1;
              }
            }
          }
        } else {
          art.precUnit = 0;
        }
        _artigos.push(art);
      }
    }
    return _artigos;
  }
  //#endregion

  //#region artigosUnidadesPrice
  public static artigosUnidadesPrice(sale: Sale, saleLines: SalesLines[], artigos: Artigos[], unidadesErp: Unidades[], defaultUnit: string): [Sale, SalesLines[]] {
    for (let l of saleLines) {
      try {
        const unidades = artigos.find(f => f.artigo === l.artigo);
        const unidadeErp = unidadesErp.find(f => f.unidade.startsWith('UN'));
        l = this.artigoUnidadesPrice(l, unidades, sale.descontoFinanceiro, unidadeErp, defaultUnit);
      } catch (e) {
        console.log('load Artigo: ' + e);
      }
    }
    let total = 0;
    let totalPrice = 0;
    for (const l of saleLines) {
      if (l.totalPriceTax === undefined || (l.totalPriceTax !== undefined && l.totalPriceTax === null)) {
        total += 0;
      } else {
        total += l.totalPriceTax;
      }

      if (l.totalPrice === undefined || (l.totalPrice !== undefined && l.totalPrice === null)) {
        totalPrice += 0;
      } else {
        totalPrice += l.totalPrice;
      }
    }
    sale.totalDocumento = Utilities.roundNumber(total, 2);
    return [sale, saleLines];
  }

  private static artigoUnidadesPrice(line: SalesLines, artigo: Artigos, descontoFinanceiro: number, unidadeErp: Unidades, defaultUnit: string, isTopSales: boolean = false): SalesLines {
    try {
      line.unidades = artigo.unidades;

      if (!isTopSales) {
        if (artigo.taxaIva !== undefined && artigo.taxaIva !== null && artigo.taxaIva > 0) {
          line.taxaIva = artigo.taxaIva;
        } else if (line.taxaIva === undefined || (line.taxaIva !== undefined && line.taxaIva === null)) {
          line.taxaIva = 0;
        }

        if (artigo.allowPriceUnit !== undefined && artigo.allowPriceUnit !== null) {
          line.allowPriceUnit = artigo.allowPriceUnit;
        } else if (line.allowPriceUnit === undefined || (line.allowPriceUnit !== undefined && line.allowPriceUnit === null)) {
          line.allowPriceUnit = false;
        }

      }
      line.descontoFinanceiro = descontoFinanceiro;
      if (line.unidades !== undefined && line.unidades && line.unidades.length > 0) {
        const unidade = (line.unidades as ArtigoUnidades[]).find(f => f.unidade === line.unidade);

        const unidadePrice = line.unidades.find(f => f.unidade.startsWith('UN'));
        const unidadeBox = line.unidades.find(f => f.unidade.startsWith('CX'));

        if (isTopSales) {
          if (unidadePrice !== undefined && unidadePrice !== null && unidadePrice.precUnit !== undefined && unidadePrice.precUnit !== null && unidadePrice.precUnit > 0) {
            line.precUnit = unidadePrice.precUnit;
          } else if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
            line.precUnit = unidade.precUnit;
          } else if (line.precUnit === undefined || (line.precUnit !== undefined && line.precUnit === null)) {
            line.precUnit = 0;
          }

          if (unidadeBox !== undefined && unidadeBox !== null && unidadeBox.precUnit !== undefined && unidadeBox.precUnit !== null && unidadeBox.precUnit > 0) {
            line.boxPrice = unidadeBox.precUnit;
          } else if (line.boxPrice === undefined || (line.boxPrice !== undefined && line.boxPrice === null)) {
            line.boxPrice = 0;
          }

          //#region descontos
          if (artigo.desconto !== undefined && artigo.desconto !== null && artigo.desconto > 0) {
            line.desconto = artigo.desconto;
          } else if (line.desconto === undefined || (line.desconto !== undefined && line.desconto === null)) {
            line.desconto = 0;
          }

          if (artigo.desconto2 !== undefined && artigo.desconto2 !== null && artigo.desconto2 > 0) {
            line.desconto2 = artigo.desconto2;
          } else if (line.desconto2 === undefined || (line.desconto2 !== undefined && line.desconto2 === null)) {
            line.desconto2 = 0;
          }

          if (line.desconto3 === undefined || (line.desconto3 !== undefined && line.desconto3 === null)) {
            line.desconto3 = 0;
          }

          if (artigo.taxaIva !== undefined && artigo.taxaIva !== null && artigo.taxaIva > 0) {
            line.taxaIva = artigo.taxaIva;
          } else if (line.taxaIva === undefined || (line.taxaIva !== undefined && line.taxaIva === null)) {
            line.desconto2 = 1;
          }
          //#endregion

          if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
            line.precUnit = unidade.precUnit;
          } else {
            line.precUnit = 0;
          }

          if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
            line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * unidade.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
          } else {
            line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
          }

          if (line.taxaIva === undefined || (line.taxaIva !== undefined && line.taxaIva === null)) {
            line.taxaIva = 1;
          }

          line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);

          if (unidade !== undefined && unidade !== null && unidade.conversao !== undefined && unidade.conversao !== null && unidade.conversao > 0) {
            line.pricePerUnit = ((line.totalPrice / unidade.conversao) / line.quantidade);
          } else {
            line.pricePerUnit = ((line.totalPrice / 1) / line.quantidade);
          }
        } else {

          if (line.unidade !== undefined && line.unidade.startsWith('CX')) {
            if (unidadeErp !== undefined) {
              const _conv = unidadeErp.unidadesConversao.find(f => f.unidadeFrom === line.unidade);
              line.boxPrice = line.precUnit;
              if (_conv !== undefined) {
                line.precUnit = (line.boxPrice / _conv.unidadeConversao);
                line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.boxPrice), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
                line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);
                line.pricePerUnit = (line.totalPrice / line.quantidade);
              }
            } else {
              line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
              line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);
            }
          } else if (line.unidade !== undefined && line.unidade.startsWith('UN')) {
            line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
            line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);

            if (unidadeBox !== undefined && unidadeBox !== null && unidadeBox.precUnit !== undefined && unidadeBox.precUnit !== null && unidadeBox.precUnit > 0) {
              line.boxPrice = unidadeBox.precUnit;
            } else if (line.boxPrice === undefined || (line.boxPrice !== undefined && line.boxPrice === null)) {
              line.boxPrice = 0;
            }

            const pricePerUnit = (line.unidades as ArtigoUnidades[]).find(f => f.unidade.startsWith(defaultUnit));
            if (pricePerUnit !== undefined) {
              line.pricePerUnit = ((line.totalPrice / pricePerUnit.conversao) / line.quantidade);
            }
          }

          line.totalPrice = Utilities.roundNumber((line.totalPrice as number), 2);
        }
      } else if (isTopSales) {
        line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
        line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);
      }

      if (Number.isNaN(line.pricePerUnit)) {
        line.pricePerUnit = 0;
      }

      if (Number.isNaN(line.boxPrice)) {
        line.boxPrice = 0;
      }
    } catch (e) {
      console.log('load Artigo: ' + e);
    }
    return line;
  }
  //#endregion

  //#region artigosUnidadesPricePurchase
  public static artigosUnidadesPricePurchase(purchase: Purchase, purchaseLines: PurchaseLines[], artigos: Artigos[], unidadesErp: Unidades[], defaultUnit: string): [Purchase, PurchaseLines[]] {
    for (let l of purchaseLines) {
      try {
        const unidades = artigos.find(f => f.artigo === l.artigo);
        const unidadeErp = unidadesErp.find(f => f.unidade.startsWith('UN'));
        l = this.artigoUnidadesPricePurchase(l, unidades, purchase.descontoFinanceiro, unidadeErp, defaultUnit);
      } catch (e) {
        console.log('load Artigo: ' + e);
      }
    }
    let total = 0;
    let totalPrice = 0;
    for (const l of purchaseLines) {
      if (l.totalPriceTax === undefined || (l.totalPriceTax !== undefined && l.totalPriceTax === null)) {
        total += 0;
      } else {
        total += l.totalPriceTax;
      }

      if (l.totalPrice === undefined || (l.totalPrice !== undefined && l.totalPrice === null)) {
        totalPrice += 0;
      } else {
        totalPrice += l.totalPrice;
      }
    }
    purchase.totalDocumento = Utilities.roundNumber(total, 2);
    return [purchase, purchaseLines];
  }

  private static artigoUnidadesPricePurchase(line: PurchaseLines, artigo: Artigos, descontoFinanceiro: number, unidadeErp: Unidades, defaultUnit: string, isTopSales: boolean = false): PurchaseLines {
    try {
      line.unidades = artigo.unidades;

      if (!isTopSales) {
        if (artigo.taxaIva !== undefined && artigo.taxaIva !== null && artigo.taxaIva > 0) {
          line.taxaIva = artigo.taxaIva;
        } else if (line.taxaIva === undefined || (line.taxaIva !== undefined && line.taxaIva === null)) {
          line.taxaIva = 0;
        }

        if (artigo.allowPriceUnit !== undefined && artigo.allowPriceUnit !== null) {
          line.allowPriceUnit = artigo.allowPriceUnit;
        } else if (line.allowPriceUnit === undefined || (line.allowPriceUnit !== undefined && line.allowPriceUnit === null)) {
          line.allowPriceUnit = false;
        }

      }
      line.descontoFinanceiro = descontoFinanceiro;
      if (line.unidades !== undefined && line.unidades && line.unidades.length > 0) {
        const unidade = (line.unidades as ArtigoUnidades[]).find(f => f.unidade === line.unidade);

        const unidadePrice = line.unidades.find(f => f.unidade.startsWith('UN'));
        const unidadeBox = line.unidades.find(f => f.unidade.startsWith('CX'));

        if (isTopSales) {
          if (unidadePrice !== undefined && unidadePrice !== null && unidadePrice.precUnit !== undefined && unidadePrice.precUnit !== null && unidadePrice.precUnit > 0) {
            line.precUnit = unidadePrice.precUnit;
          } else if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
            line.precUnit = unidade.precUnit;
          } else if (line.precUnit === undefined || (line.precUnit !== undefined && line.precUnit === null)) {
            line.precUnit = 0;
          }

          if (unidadeBox !== undefined && unidadeBox !== null && unidadeBox.precUnit !== undefined && unidadeBox.precUnit !== null && unidadeBox.precUnit > 0) {
            line.boxPrice = unidadeBox.precUnit;
          } else if (line.boxPrice === undefined || (line.boxPrice !== undefined && line.boxPrice === null)) {
            line.boxPrice = 0;
          }

          //#region descontos
          if (artigo.desconto !== undefined && artigo.desconto !== null && artigo.desconto > 0) {
            line.desconto = artigo.desconto;
          } else if (line.desconto === undefined || (line.desconto !== undefined && line.desconto === null)) {
            line.desconto = 0;
          }

          if (artigo.desconto2 !== undefined && artigo.desconto2 !== null && artigo.desconto2 > 0) {
            line.desconto2 = artigo.desconto2;
          } else if (line.desconto2 === undefined || (line.desconto2 !== undefined && line.desconto2 === null)) {
            line.desconto2 = 0;
          }

          if (line.desconto3 === undefined || (line.desconto3 !== undefined && line.desconto3 === null)) {
            line.desconto3 = 0;
          }

          if (artigo.taxaIva !== undefined && artigo.taxaIva !== null && artigo.taxaIva > 0) {
            line.taxaIva = artigo.taxaIva;
          } else if (line.taxaIva === undefined || (line.taxaIva !== undefined && line.taxaIva === null)) {
            line.desconto2 = 1;
          }
          //#endregion

          if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
            line.precUnit = unidade.precUnit;
          } else {
            line.precUnit = 0;
          }

          if (unidade !== undefined && unidade !== null && unidade.precUnit !== undefined && unidade.precUnit !== null && unidade.precUnit > 0) {
            line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * unidade.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
          } else {
            line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
          }

          if (line.taxaIva === undefined || (line.taxaIva !== undefined && line.taxaIva === null)) {
            line.taxaIva = 1;
          }

          line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);

          if (unidade !== undefined && unidade !== null && unidade.conversao !== undefined && unidade.conversao !== null && unidade.conversao > 0) {
            line.pricePerUnit = ((line.totalPrice / unidade.conversao) / line.quantidade);
          } else {
            line.pricePerUnit = ((line.totalPrice / 1) / line.quantidade);
          }
        } else {

          if (line.unidade !== undefined && line.unidade.startsWith('CX')) {
            if (unidadeErp !== undefined) {
              const _conv = unidadeErp.unidadesConversao.find(f => f.unidadeFrom === line.unidade);
              line.boxPrice = line.precUnit;
              if (_conv !== undefined) {
                line.precUnit = (line.boxPrice / _conv.unidadeConversao);
                line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.boxPrice), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
                line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);
                line.pricePerUnit = (line.totalPrice / line.quantidade);
              }
            } else {
              line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
              line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);
            }
          } else if (line.unidade !== undefined && line.unidade.startsWith('UN')) {
            line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
            line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);

            if (unidadeBox !== undefined && unidadeBox !== null && unidadeBox.precUnit !== undefined && unidadeBox.precUnit !== null && unidadeBox.precUnit > 0) {
              line.boxPrice = unidadeBox.precUnit;
            } else if (line.boxPrice === undefined || (line.boxPrice !== undefined && line.boxPrice === null)) {
              line.boxPrice = 0;
            }

            const pricePerUnit = (line.unidades as ArtigoUnidades[]).find(f => f.unidade.startsWith(defaultUnit));
            if (pricePerUnit !== undefined) {
              line.pricePerUnit = ((line.totalPrice / pricePerUnit.conversao) / line.quantidade);
            }
          }

          line.totalPrice = Utilities.roundNumber((line.totalPrice as number), 2);
        }
      } else if (isTopSales) {
        line.totalPrice = Utilities.CalculateDiscountRound(true, 2, (line.quantidade * line.precUnit), line.desconto, line.desconto2, line.desconto3, line.descontoFinanceiro);
        line.totalPriceTax = Utilities.CalculateDiscountRound(true, 2, (line.totalPrice * line.taxaIva), 0, 0, 0, 0);
      }

      if (Number.isNaN(line.pricePerUnit)) {
        line.pricePerUnit = 0;
      }

      if (Number.isNaN(line.boxPrice)) {
        line.boxPrice = 0;
      }
    } catch (e) {
      console.log('load Artigo: ' + e);
    }
    return line;
  }
  //#endregion
  //#endregion

  //#region serie
  public static Series(documents: Documents[], tipoDoc: string): [Documents[], string] {
    let dataSerie: Documents[] = [];
    let serie = '';
    if (documents) {
      dataSerie = documents.filter(f => f.tipoDoc === tipoDoc);
      let serieNow = dataSerie.find(f => f.seriePorDefeito === true && (Utilities.parseDate(f.dataInicial) <= Utilities.dateNow() && Utilities.parseDate(f.dataFinal) >= Utilities.dateNow()));
      if (serieNow) {
        serie = serieNow.serie;
      } else {
        serieNow = dataSerie.find(f => (Utilities.parseDate(f.dataInicial) <= Utilities.dateNow() && Utilities.parseDate(f.dataFinal) >= Utilities.dateNow()));
        if (serieNow) {
          serie = serieNow.serie;
        }
      }
    }
    return [dataSerie, serie];
  }
  //#endregion

  //#region documents
  public static Documents(documents: Documents[], tipoDoc: string): [string, boolean, string, number, boolean] {
    const unidade: string = this.getUnidade(documents, tipoDoc);
    let showObs = false;
    let description = 'N/A';
    let orderType = 0;
    let approval = false;

    if (documents && documents != null && tipoDoc && tipoDoc !== undefined) {
      const dUnit = documents.find(f => f.tipoDoc === tipoDoc);
      if (!Utilities.isNullOrUndefined(dUnit)) {
        if (dUnit.showObs) {
          showObs = dUnit.showObs;
        }
        if (dUnit.descricao) {
          description = dUnit.descricao;
        }

        if (dUnit.codePortal) {
          orderType = dUnit.codePortal;
        }

        if (dUnit.approval) {
          approval = dUnit.approval;
        }
      }
    }

    return [unidade, showObs, description, orderType, approval];
  }
  //#endregion

  //#region approval
  public static ApprovalWorkFlow(dataApproval: ApprovalWorkflow[], sale: Sale, idCurrentUser: string, verification: boolean, isAdmin: boolean): boolean {
    let flag = false;

    if (!Utilities.isNullOrUndefined(dataApproval) && dataApproval.length > 0 && !Utilities.isNullOrUndefined(sale)) {
      const saleFilter: SaleFilterValidation[] = [];
      for (const line of sale.lines) {
        const _sale: SaleFilterValidation = new SaleFilterValidation();
        Object.assign(_sale, sale);
        Object.assign(_sale, line);
        saleFilter.push(_sale);
      }

      if (!Utilities.isNullOrUndefined(saleFilter) && saleFilter.length > 0) {
        const isApprove = false;
        for (const approval of dataApproval) {
          const result = this.approvalWorkFlowSteps(sale, saleFilter, approval.roleApproval, 0, idCurrentUser, verification, isAdmin, isApprove);
          if (result) {
            flag = true;
          }
        }
      }
    }
    return flag;
  }

  private static approvalWorkFlowSteps(sale: Sale, saleFilter: SaleFilterValidation[], roleApproval: RoleApprovalWorkflow[], idParente: number, idCurrentUser: string, verification: boolean, isAdmin: boolean, isApprove: boolean): boolean {
    let flag = false;

    const steps = roleApproval.filter(f => f.idParent === idParente);
    if (!Utilities.isNullOrUndefined(steps) && steps.length > 0) {
      for (const step of steps) {
        if (step.type === 'filter' && !Utilities.isNullOrUndefined(step.filter)) {
          const dataSource: DataSource = new DataSource({ store: saleFilter, requireTotalCount: true });
          dataSource.reload();
          dataSource.filter(step.filter.filterExpression);
          dataSource.reload();
          const total = dataSource.totalCount();
          if (total < 1) {
            return false;
          } else {
            flag = true;
          }
        } else if (step.type === 'approval' && !Utilities.isNullOrUndefined(step.approval)) {
          if (!Utilities.isNullOrUndefined(sale.approvals) && sale.approvals.length > 0) {
            const approvals = roleApproval.filter(f => f.type === step.type).sort(s => s.id);
            if (approvals.length > sale.approvals.length) {
              let exitApproval = false;
              for (const s of sale.approvals) {
                if (!Utilities.isNullOrUndefined(s.idOrder) && s.idOrder > 0 && !Utilities.isNullOrUndefined(s.roleApproval.find(f => f.id === step.approval.id))) {
                  exitApproval = true;
                }
              }

              if (!exitApproval) {
                const approval: Approval = new Approval();
                approval.roleApproval = [];
                approval.roleApproval.push(step.approval);
                approval.estado = 'New';
                approval.idCompany = sale.idCompany;
                // approval.roleApproval = step.approval;
                sale.approvals.push(approval);
              }
            }

            if (!Utilities.isNullOrUndefined(sale.approvedSales) && approvals.length > 0) {
              for (const appWork of approvals) {
                if (!Utilities.isNullOrUndefined(appWork.approval)) {
                  if (!Utilities.isNullOrUndefined(appWork.approval.users) && appWork.approval.users.length > 0) {
                    const r = appWork.approval.users.find(f => f === sale.approvedSales.idUser);
                    if (!Utilities.isNullOrUndefined(r) || (Utilities.isNullOrUndefined(r) && isAdmin)) {
                      if (verification) {
                        flag = true;
                      } else {
                        const rSale = sale.approvals.filter(f => f.estado === 'New');
                        if (!Utilities.isNullOrUndefined(rSale) && rSale.length > 0) {
                          for (const _sale of rSale) {
                            if (!Utilities.isNullOrUndefined(_sale.roleApproval)) {
                              for (const app of _sale.roleApproval) {
                                const userpApp = app.users.find(f => f === idCurrentUser);
                                if (!Utilities.isNullOrUndefined(userpApp) || (Utilities.isNullOrUndefined(userpApp) && isAdmin)) {
                                  if (_sale.estado === 'New' && !isApprove) {
                                    _sale.idUser = idCurrentUser;
                                    _sale.estado = 'APR';
                                    _sale.date = sale.approvedSales.data;
                                    isApprove = true;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            sale.approvals = [];
            const approval: Approval = new Approval();
            approval.roleApproval = [];
            approval.roleApproval.push(step.approval);
            approval.estado = 'New';
            approval.idCompany = sale.idCompany;
            sale.approvals.push(approval);
          }
        }
        const result = this.approvalWorkFlowSteps(sale, saleFilter, roleApproval, step.id, idCurrentUser, verification, isAdmin, isApprove);
        if (result) {
          flag = true;
        }
      }
    }
    return flag;
  }
  //#endregion

  //#region approval Purchases
  public static ApprovalWorkFlowPurchase(dataApproval: ApprovalWorkflow[], purchase: Purchase, idCurrentUser: string, verification: boolean, isAdmin: boolean): boolean {
    let flag = false;

    if (!Utilities.isNullOrUndefined(dataApproval) && dataApproval.length > 0 && !Utilities.isNullOrUndefined(purchase)) {
      const purchaseFilter: PurchaseFilterValidation[] = [];
      for (const line of purchase.lines) {
        const _purchase: PurchaseFilterValidation = new PurchaseFilterValidation();
        Object.assign(_purchase, purchase);
        Object.assign(_purchase, line);
        purchaseFilter.push(_purchase);
      }

      if (!Utilities.isNullOrUndefined(purchaseFilter) && purchaseFilter.length > 0) {
        const isApprove = false;
        for (const approval of dataApproval) {
          const result = this.approvalWorkFlowStepsPurchase(purchase, purchaseFilter, approval.roleApproval, 0, idCurrentUser, verification, isAdmin, isApprove);
          if (result) {
            flag = true;
          }
        }
      }
    }
    return flag;
  }

  private static approvalWorkFlowStepsPurchase(purchase: Purchase, purchaseFilter: PurchaseFilterValidation[], roleApproval: RoleApprovalWorkflow[], idParente: number, idCurrentUser: string, verification: boolean, isAdmin: boolean, isApprove: boolean): boolean {
    let flag = false;

    const steps = roleApproval.filter(f => f.idParent === idParente);
    if (!Utilities.isNullOrUndefined(steps) && steps.length > 0) {
      for (const step of steps) {
        if (step.type === 'filter' && !Utilities.isNullOrUndefined(step.filter)) {
          const dataSource: DataSource = new DataSource({ store: purchaseFilter, requireTotalCount: true });
          dataSource.reload();
          dataSource.filter(step.filter.filterExpression);
          dataSource.reload();
          const total = dataSource.totalCount();
          if (total < 1) {
            return false;
          } else {
            flag = true;
          }
        } else if (step.type === 'approval' && !Utilities.isNullOrUndefined(step.approval)) {
          if (!Utilities.isNullOrUndefined(purchase.approvals) && purchase.approvals.length > 0) {
            const approvals = roleApproval.filter(f => f.type === step.type).sort(s => s.id);
            if (approvals.length > purchase.approvals.length) {
              let exitApproval = false;
              for (const s of purchase.approvals) {
                if (!Utilities.isNullOrUndefined(s.idOrder) && s.idOrder > 0 && !Utilities.isNullOrUndefined(s.roleApproval.find(f => f.id === step.approval.id))) {
                  exitApproval = true;
                }
              }

              if (!exitApproval) {
                const approval: Approval = new Approval();
                approval.roleApproval = [];
                approval.roleApproval.push(step.approval);
                approval.estado = 'New';
                approval.idCompany = purchase.idCompany;
                // approval.roleApproval = step.approval;
                purchase.approvals.push(approval);
              }
            }

            if (!Utilities.isNullOrUndefined(purchase.approvedPurchase) && approvals.length > 0) {
              for (const appWork of approvals) {
                if (!Utilities.isNullOrUndefined(appWork.approval)) {
                  if (!Utilities.isNullOrUndefined(appWork.approval.users) && appWork.approval.users.length > 0) {
                    const r = appWork.approval.users.find(f => f === purchase.approvedPurchase.idUser);
                    if (!Utilities.isNullOrUndefined(r) || (Utilities.isNullOrUndefined(r) && isAdmin)) {
                      if (verification) {
                        flag = true;
                      } else {
                        const rSale = purchase.approvals.filter(f => f.estado === 'New');
                        if (!Utilities.isNullOrUndefined(rSale) && rSale.length > 0) {
                          for (const _sale of rSale) {
                            if (!Utilities.isNullOrUndefined(_sale.roleApproval)) {
                              for (const app of _sale.roleApproval) {
                                const userpApp = app.users.find(f => f === idCurrentUser);
                                if (!Utilities.isNullOrUndefined(userpApp) || (Utilities.isNullOrUndefined(userpApp) && isAdmin)) {
                                  if (_sale.estado === 'New' && !isApprove) {
                                    _sale.idUser = idCurrentUser;
                                    _sale.estado = 'APR';
                                    _sale.date = purchase.approvedPurchase.data;
                                    isApprove = true;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            purchase.approvals = [];
            const approval: Approval = new Approval();
            approval.roleApproval = [];
            approval.roleApproval.push(step.approval);
            approval.estado = 'New';
            approval.idCompany = purchase.idCompany;
            purchase.approvals.push(approval);
          }
        }
        const result = this.approvalWorkFlowStepsPurchase(purchase, purchaseFilter, roleApproval, step.id, idCurrentUser, verification, isAdmin, isApprove);
        if (result) {
          flag = true;
        }
      }
    }
    return flag;
  }
  //#endregion
}
